import React from "react";
import util from "../../utils/util";
import moment from "moment/moment";
import { Page, Text, View, Document } from '@react-pdf/renderer';
import TableRows from "../components/TableRows";
import TableHeader from "../components/TableHeader";
import styles from "../components/ReportStyles";
import {DateRange, toLongFormat} from "../../model/Common";
import {
  EmployeeSummary,
  MobilityPremiumEmployeeEntry,
  MobilityPremiumEntry,
  MobilityPremiumReport
} from "../../../libs/api/dal/api-model";
import {ColumnDefinition} from "../components/PdfTypes";
import {Style} from "@react-pdf/types";

type MobilityPremiumsDocumentProps = {
  columns: ColumnDefinition<MobilityPremiumEntry>[]
  data: MobilityPremiumReport
  period: DateRange
}

const MobilityPremiumsDocument: React.FC<MobilityPremiumsDocumentProps> = (
  { columns, data, period }
) => (
  <Document>
    <Page size="A4" style={styles.page} orientation={"portrait"} wrap>
      {/*<Image style={styles.image} src={Logo} fixed />*/}
      {/*<Image style={styles.image} src='/logo-ve-small.jpg' fixed />*/}
      <ReportHeader period={period} />
      {util.isDefined(data) && util.isDefined(data.entries) && Array.isArray(data.entries) &&
        data.entries.map((entry, i) =>
          <TableSection index={i} key={entry.employee.id} columns={columns} entry={entry} />
        )
      }
      <Text style={styles.date} fixed>{ moment().format('YYYY-MM-DD HH:mm') }</Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

const ReportHeader: React.FC<{ period: DateRange }> = ({ period }) =>
  <View style={styles.header} fixed>
    <Text style={styles.title}>Mobiliteit Premies</Text>
    <Text style={styles.subtitle}>{ toLongFormat(period) }</Text>
  </View>

type MobilityPremiumProps = {
  index: number
  columns: ColumnDefinition<MobilityPremiumEntry>[]
  entry: MobilityPremiumEmployeeEntry
}

const TableSection: React.FC<MobilityPremiumProps> = (
  {
    index,
    columns,
    entry: { entries, employee, totalKm, totalPremium },
  }
) =>
  <View style={styles.section} break={index !== 0}>
    <Text style={styles.tabletitle} fixed>{employee.name}</Text>
    <View style={styles.tableContainer}>
      <TableHeader columns={columns} style={styles.columnHeader} />
      <TableRows columns={columns} data={entries} style={styles.tableRow} />
      <TableFooter columns={columns}
                   employee={employee}
                   totalKm={util.formatInt(totalKm)}
                   totalPremium={util.formatCurrency(totalPremium)}
      />
    </View>
  </View>

type MobilityPremiumFooterProps = {
  "columns": ColumnDefinition<MobilityPremiumEntry>[]
  "employee": EmployeeSummary,
  "totalPremium": string,
  "totalKm": string,
}

const TableFooter: React.FC<MobilityPremiumFooterProps> = (
  { columns, employee, totalKm, totalPremium }
) => {
  const colStyle = (i: number): Style[] => {
    const colStyleAtIndex = columns[columns.length - i].styles;
    return (colStyleAtIndex ? [colStyleAtIndex] : []);
  };
  return (
    <View style={styles.tableFooter}>
      <Text style={ [{ width: '70%', textAlign: 'left'}, styles.columnFooter] }>Totaal voor { employee.name }</Text>
      <Text style={ colStyle(2).concat([styles.columnFooter]) }>{ totalKm }</Text>
      <Text style={ colStyle(1).concat([styles.columnFooter]) }>{ totalPremium }</Text>
    </View>
  );
}

export default MobilityPremiumsDocument;
