import moment from "moment";
import util from "../../utils/util";
import {WorkUnitTimeEntry} from "../../../libs/api/dal/api-model";
import {ColumnDefinition} from "../components/PdfTypes";
import {Style} from "@react-pdf/types";
import {columnBorderColor} from "../components/ReportStyles";

const columnStyle: (styles: Style) => Style = (styles) => {
  const defaults: Style = {
    borderRightColor: columnBorderColor,
    borderRightWidth: 1,
    textAlign: 'left',
  }
  return {
    ...defaults,
    ...styles
  };
}
const columns: ColumnDefinition<WorkUnitTimeEntry>[] = [
  {
    header: "Datum",
    accessorPath: "date",
    styles: columnStyle({ width: '10%'}),
  },
  {
    header: "Project",
    accessorPath: 'projectGroup.groupId',
    styles: columnStyle({ width: '10%', textAlign: 'right'}),
  },
  {
    header: "Klant",
    accessorFn: ({projectGroup}) =>
      [projectGroup?.company, projectGroup?.location].filter(util.isDefined).join(' | '),
    styles: columnStyle({ width: '20%', textAlign: 'left'})
  },
  {
    header: "Omschrijving",
    accessorPath: 'projectGroup.description',
    styles: columnStyle({ width: '32%', textAlign: 'left'})
  },
  {
    header: "Begin",
    accessorFn: ({begin}) => moment(begin, 'HH:mm').format('HH:mm'),
    styles: columnStyle({ width: '7%', textAlign: 'center' }),
  },
  {
    header: "Eind",
    accessorFn: ({end}) => moment(end, 'HH:mm').format('HH:mm'),
    styles: columnStyle({ width: '7%', textAlign: 'center' }),
  },
  {
    header: "Pauze",
    accessorFn: ({pause}) => util.duration(pause),
    styles: columnStyle({ width: '7%', textAlign: 'center' }),
  },
  {
    header: "Prestatie",
    accessorFn: ({duration}) => util.duration(duration),
    styles: {
      width: '7%',
      borderRightWidth: 0,
      textAlign: 'center',
    },
  }
];

export { columns };
