import React from "react";
import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {Edit, Trash, UserCheck} from "react-feather";
import util from "../../utils/util";
import {Activity as ActivityModel, ProjectGroupRef, TimesheetEntry} from "../../../libs/api/time/api-model";
import ActivityBadgeNew from "../components/ActivityBadgeNew";

type EntityActionHandler<T> = (entity: T) => void;

type ActionButtonProps<T> = {
  value: T
  actionHandler: EntityActionHandler<T>
}

type ActionProps<T> = {
  value: T
  handleEdit: EntityActionHandler<T>
  handleDelete: EntityActionHandler<T>
}

const Project: React.FC<{ value: ProjectGroupRef }> = ({ value }) =>
  <div>
    { value.groupId }<small className="ms-2 text-muted text-truncate">{value.location} - {value.company}</small><br/>
    { value.description }
  </div>

const Activity: React.FC<{ value: ActivityModel }> = ({ value }) =>
  <>
    <ActivityBadgeNew activity={value} /><br/>
    <small className="text-muted">{value.name}</small>
  </>
  
const Driver: React.FC<{ value: boolean | undefined }> = ({ value }) =>
  <>
    { typeof value !== 'undefined' && value && <UserCheck className="text-success" size={18} /> }
  </>

const Hours: React.FC<{value: string}> = ({value}) =>
  <>
    { util.localTimeToHours({value: value}) }
  </>

const Duration: React.FC<{value: string}> = ({value}) =>
  <>
    { util.duration(value) }
  </>

const EditPostCalc: React.FC<ActionButtonProps<TimesheetEntry>> = ({value, actionHandler}) =>
  <Button variant="link" size={"sm"} className="p-0" onClick={_ => actionHandler(value)}>
    <Edit className="me-1" size={18} />
  </Button>

const DeletePostCalc: React.FC<ActionButtonProps<TimesheetEntry>> = ({value, actionHandler}) =>
  <Button variant="link" size="sm" className="text-danger p-0" onClick={_ => actionHandler(value)}>
    <Trash className="me-1" size={18} />
  </Button>

const PostCalcActions: React.FC<ActionProps<TimesheetEntry>> = ({value, handleEdit, handleDelete}) =>
  <ButtonToolbar className="justify-content-end">
    <ButtonGroup size={"sm"} vertical={false}>
      <EditPostCalc value={value} actionHandler={handleEdit} />
      <DeletePostCalc value={value} actionHandler={handleDelete} />
    </ButtonGroup>
  </ButtonToolbar>

const postCalculationColumns = (handleEdit: EntityActionHandler<TimesheetEntry>, handleDelete: EntityActionHandler<TimesheetEntry>) => [
  {
    Header: "Activiteit",
    accessor: "activityRef",
    className: "text-center",
    Cell: Activity,
    width: "7%",
  },
  {
    Header: "Project",
    accessor: "projectRef",
    Cell: Project,
  },
  {
    Header: "Begin",
    accessor: "start",
    width: "7%",
    className: "text-center",
    Cell: Hours,
  },
  {
    Header: "Eind",
    accessor: "end",
    width: "7%",
    className: "text-center",
    Cell: Hours,
  },
  {
    Header: "Pauze",
    accessor: "pause",
    width: "7%",
    className: "text-center",
    Cell: Duration,
  },
  {
    Header: "Duurtijd",
    accessor: "duration",
    width: "7%",
    className: "text-center",
    Cell: Duration,
  },
  {
    Header: "Kilometers",
    accessor: "km",
    width: "7%",
    className: "text-end",
  },
  {
    Header: "Bestuurder",
    accessor: "driver",
    Cell: Driver,
    width: "7%",
    className: "text-center",
  },
  {
    Header: "Voertuig",
    accessor: "licensePlate",
    width: "10%",
  },
  {
    Header: "Description",
    id: "description",
    accessor: "description",
  },
  {
    Header: "Acties",
    id: "actions",
    className: "text-end table-action",
    accessor: 'id',
    width: "7%",
    Cell: (data: any) => <PostCalcActions value={data.row.original} handleEdit={handleEdit} handleDelete={handleDelete} />
  }
];

const renderTimesheetDescription = (row: any, visibleColumnsLength: number) => (
  <>
    <td>&nbsp;</td>
    <td colSpan={visibleColumnsLength - 1}>
      <span className='fst-italic text-primary'>{row.values.description}</span>
    </td>
  </>
);

export { postCalculationColumns, renderTimesheetDescription };
